import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPage = ({ pathContext: { locale } }) => (
    <Layout locale={locale}>
        <SEO title="Privacy Policy"/>
        <div className={"container"}>
            <div className={"content"}>
                <div className={"title"}>
                    <h1>Privacy Policy</h1>
                </div>
                <h3>Registration & Product usage</h3>
                <p>When registering to Reko, we collect and store your business name and domain.</p>
                <p>When using Reko on your site, no personal information is stored if not explicitly set by you on the site's content. All data collection is anonymized. <a href="https://policies.google.com/technologies/partner-sites">Google Analytics</a> terms of service apply.</p>
                <h3>This site</h3>
                <p>We use cookies and other identifiers for <a href="https://policies.google.com/technologies/partner-sites">Google Analytics</a> on this site to gather analytics.</p>
                {/* <p>We use <a href="https://www.netlify.com/gdpr/">Netlify</a> to process the form data. The form data is used to contact users who have given their information and consent to use this data for contacting them. </p> */}
                <p>By using our website, you hereby consent to our Privacy Policy.</p>
            </div>
        </div>
    </Layout>
)

export default PrivacyPage
